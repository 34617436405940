import React, { useState } from "react";
import * as Constants from '../constants'
import LabelRadio from "./swe/base/label-radio";
import { FeedbackOptions } from "../utils/helpers";

const Footer = () => {
  const [feedbackOption, setFeedbackOption] = useState('');


  return (
    <>
      <footer className="qg-site-footer">
        <div className="container qg-site-width">

          <h2 className="collapse">Support links</h2>

          <div className="row">

            <div className="col-12 col-sm-6 col-md-4">
              <div className="qg-footer-col-content sm-border-bottom">
                <h3>Contact us</h3>
                <ul>
                  <li>For general enquiries, feedback, complaints and compliments: <p className="phone"><span>13 QGOV (<a href="tel:137468" target="_blank" rel="noopener noreferrer" className="d-inline" data-analytics-link-group="qg-contact-phone">13 74 68</a>)</span></p></li>
                  <li className="facebook"><a href="https://www.facebook.com/QueenslandGovernment/" target="_blank" rel="noopener noreferrer" aria-label="Facebook /QueenslandGovernment" data-analytics-link-group="qg-contact-facebook">/QueenslandGovernment</a></li>
                  <li className="twitter">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="17" height="17" rx="4" fill="white" />
                      <path d="M11.3021 4H12.9194L9.38693 8.0365L13.5426 13.53H10.2895L7.7398 10.1991L4.82582 13.53H3.20618L6.98382 9.21172L3 4H6.3355L8.63782 7.04456L11.3021 4ZM10.734 12.5633H11.6297L5.84755 4.91635H4.88538L10.734 12.5633Z" fill="#005E85" />
                    </svg>
                    <a href="https://twitter.com/qldgov" target="_blank" rel="noopener noreferrer" aria-label="Twitter @QldGov" data-analytics-link-group="qg-contact-twitter">@QldGov</a></li>
                </ul>

                <a className="btn btn-global-secondary" href="https://www.qld.gov.au/contact-us" target="_blank" rel="noopener noreferrer">Other contact options</a>
              </div>
            </div>

            {/* <div class="col-12 col-sm-6 col-md-4">
              <div class="qg-footer-col-content qg-service-centre sm-border-bottom">
                <h3>Your nearest service centre</h3>

                <div class="qg-service-centre__wrapper" data-types="QGAP; HSC" data-centres="https://discover.search.qld.gov.au/s/search.json?collection=qgov~sp-services&sort=prox">

                  <div class="qg-location-default">
                    <p>Help us provide you with the most useful information by setting your location.</p>
                    <button class="btn btn-global-primary-white qg-service-centre-set-location collapsed" data-analytics-link-group="qg-nearest-service-set-location" data-toggle="collapse" data-target="#qg-service-centre-location-setter" role="button" aria-expanded="false" aria-controls="qg-service-centre-location-setter">
                      <span class="qg-service-centre-set-location__text">Set your location</span>
                    </button>
                    <div id="qg-service-centre-location-setter" class="qg-location-setter-wrap collapse">
                      <div class="qg-location-setter show">
                        <button class="btn btn-global-primary detect-location" type="button">Use device location</button>
                        <span class="qg-location-setter-divider">or</span>
                        <form class="qg-location-setter-form" action="#">
                          <label>Start typing your town or suburb</label>
                          <p class="qg-location-setter-error error hide">Please enter a location into the search box and try searching again.</p>
                          <input type="text" aria-label="Start typing your town or suburb, e.g. Cooladdi" data-choice="" placeholder="e.g. Cooladdi" />
                          <div class="qg-location-setter-autocomplete hide">
                            <ul>
                              <li><button><b>Coola</b>bine, Sunshine Coast Regional</button></li>
                              <li><button><b>Coola</b>bunia, South Burnett Regional</button></li>
                              <li><button><b>Coola</b>ddi, Murweh Shire</button></li>
                              <li><button><b>Coola</b>na, Somerset Regional</button></li>
                              <li><button><b>Coola</b>ngatta, Gold Coast City</button></li>
                            </ul>
                          </div>
                          <button class="btn btn-global-primary set-location" type="button">Set location</button>
                        </form>
                        <button class="qg-location-setter-close">Close</button>
                      </div>
                    </div>
                  </div>

                  <div class="qg-location-set hide">
                    <div class="qg-service-centre__results" data-jp="https://www.qld.gov.au/law/legal-mediation-and-justice-of-the-peace/about-justice-of-the-peace/search-for-your-nearest-jp-or-cdec/view?title=" data-qgap="https://www.qld.gov.au/about/contact-government/contacts/government-service-offices/view?title=" data-tmsc="https://www.qld.gov.au/transport/contacts/centres/view?title=" data-hsc="https://www.qld.gov.au/housing/public-community-housing/housing-service-centre/view?">
                      <a href="#" class="qg-service-centre__link" data-analytics-link-group="qg-nearest-service-centre-details">Quilpie Police Station</a>
                      <ul class="qg-service-centre-list">
                        <li class="qg-service-centre-list-item"><a href="#" data-analytics-link-group="qg-nearest-service-centre-services">Services available</a></li>
                        <li class="qg-service-centre-list-item">138 km away</li>
                        <li class="qg-service-centre-list-item"><span class="qg-service-centre__address">7 Bulnbuln Street</span><span class="qg-service-centre__address">Quilpie 4480</span></li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                </div>

                <a href="https://www.qld.gov.au/about/contact-government/contacts/government-service-offices" class="btn btn-global-secondary" data-analytics-link-group="qg-nearest-service-centre-all">All service centre locations</a>

              </div>
            </div> */}

            <div className="col-12 col-sm-6 col-md-4">

              <div className="qg-footer-col-content qg-feedback sm-border-bottom">

                <h3>Website feedback</h3>

                <p>Help us improve the content on our website or tell us what is working really well.</p>

                <div className="qg-footer-feedback-wrap">
                  <a className="btn btn-global-secondary qg-feedback-toggle" data-toggle="collapse" href="#qg-footer-feedback" role="button" aria-expanded="false" aria-controls="qg-footer-feedback" data-analytics-link-group="qg-feedback">
                    <span className="qg-feedback-toggle__text">Leave your feedback</span>
                  </a>

                  <div id="qg-footer-feedback" className="qg-footer-feedback__v2 collapse">
                    <div className="qg-footer-feedback-content">
                      
                      <form id="qg-page-feedback-form" method="post" action="https://www.smartservice.qld.gov.au/services/submissions/email/feedback/feedback-v2" target="_blank" rel="noopener noreferrer" className="form qg-forms-v2" data-recaptcha="true" data-action="feedback" noValidate={true}>
                        <ol className="questions pt-2">
                          {/* <li>
                            <fieldset>
                              <legend className="pb-2">
                                <span className="label">Is your feedback about:</span>
                                <abbr className="required" title="(required)">*</abbr>
                              </legend>
                              <ol className="choices qg-forms-v2__radio">
                                <li>
                                  <input name="page-feedback-about" id="page-feedback-about-this-website" type="radio" value="this website" data-qg-pr="default" data-parent="#qg-page-feedback-form" data-target="#feedback-page" data-toggle="false" data-hide-others="true"/>
                                  <label htmlFor="page-feedback-about-this-website">this website</label>
                                </li>
                                <li>
                                  <input name="page-feedback-about" id="page-feedback-about-a-government-service" type="radio" value="a government service" data-qg-pr="default" data-parent="#qg-page-feedback-form" data-target="#feedback-serv-dep-staff" data-toggle="false" data-hide-others="true" />
                                  <label htmlFor="page-feedback-about-a-government-service">a government service, department or staff member?</label>
                                </li>
                              </ol>
                            </fieldset>
                          </li> */}
                          <li>
                            <LabelRadio
                              id="page-feedback-about-selection"
                              label="Is your feedback about:"
                              inputValue={feedbackOption}
                              setInputValue={setFeedbackOption}
                              options={FeedbackOptions}
                          />
                          </li>
                        </ol>

                        <div className="panel">

                          <div id="feedback-serv-dep-staff" className="status global-info panel-collapse collapse" style={{display: feedbackOption === 'feedback-about-a-government-service' ? 'block' : 'none'}}>
                            <h4><span className="fa fa-info-circle"></span>Feedback on government services, departments and staff</h4>
                            <p>Please use our <a href="https://www.qld.gov.au/contact-us/complaints/" target="_blank" rel="noopener noreferrer" data-analytics-link-group="qg-feedback-non-website">complaints and compliments form</a>.</p>
                          </div>

                          <div id="feedback-page" className="panel-collapse collapse" style={{display: feedbackOption === 'feedback-about-this-website' ? 'block' : 'none'}}>
                            <ol className="questions pt-2">
                              <li>
                                <fieldset>
                                  <legend className="pb-2">
                                    <span className="label">How satisfied are you with your experience today?</span>
                                    <abbr className="required" title="(required)">*</abbr>
                                  </legend>
                                  <ol className="choices qg-forms-v2__radio">
                                    <li>
                                      <input type="radio" name="feedback-satisfaction" value="Very dissatisfied" required id="fs-very-dissatisfied" />
                                      <label htmlFor="fs-very-dissatisfied">Very dissatisfied (1)</label>
                                    </li>
                                    <li>
                                      <input type="radio" name="feedback-satisfaction" value="Dissatisfied" required id="fs-dissatisfied" />
                                      <label htmlFor="fs-dissatisfied">Dissatisfied (2)</label>
                                    </li>
                                    <li>
                                      <input type="radio" name="feedback-satisfaction" value="Neither satisfied or dissatisfied" required id="fs-neither-satisfied-or-dissatisfied" />
                                      <label htmlFor="fs-neither-satisfied-or-dissatisfied">Neither satisfied or dissatisfied (3)</label>
                                    </li>
                                    <li>
                                      <input type="radio" name="feedback-satisfaction" value="Satisfied" required id="fs-satisfied" />
                                      <label htmlFor="fs-satisfied">Satisfied (4)</label>
                                    </li>
                                    <li>
                                      <input type="radio" name="feedback-satisfaction" value="Very satisfied" required id="fs-very-satisfied" />
                                      <label htmlFor="fs-very-satisfied">Very satisfied (5)</label>
                                    </li>
                                  </ol>
                                </fieldset>
                              </li>
                            </ol>

                            <ol className="questions pt-2">
                              <li>
                                <label htmlFor="comments" className="pb-2">
                                  <span className="label">Comments</span>
                                  <abbr title="(required)" className="required">*</abbr>
                                </label>
                                <textarea className="form-control" name="comments" id="comments" rows="6" cols="40" required="required"></textarea>
                              </li>

                              <li className="footer col-md-12 mt-3">
                                <span id="feedback-hidden-inputs"></span>

                                <ul className="actions">
                                  <li>
                                    <button type="submit" value="Submit feedback" className="btn btn-global-primary" data-analytics-link-group="qg-feedback-website">Submit feedback</button>
                                  </li>
                                </ul>
                                <p className="captchaPrivacyTerms pb-3">
                                  This site is protected by reCAPTCHA and the Google
                                  <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and
                                  <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                </p>

                              </li>
                            </ol>

                          </div>

                        </div>
                      </form>

                      <div className="thankyou d-none"></div>

                      <div className="qg-footer-feedback-footer">
                        <a className="qg-footer-feedback__close" data-toggle="collapse" href="#qg-footer-feedback" role="button" aria-expanded="false" aria-controls="qg-footer-feedback">Close</a>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div className="row">
            <div className="col-12 col-md-8">
              <div className="qg-cultural-notice qg-footer-col-content sm-border-bottom">

                <h3>Cultural acknowledgement</h3>

                <p>We pay our respects to the Aboriginal and Torres Strait Islander ancestors of this land, their spirits and their legacy. The foundations laid by these ancestors—our First Nations peoples—give strength, inspiration and courage to current and future generations towards creating a better Queensland.
                </p>

              </div>
            </div>
          </div>

          <div className="qg-legal d-flex flex-wrap align-items-end justify-content-between">

            {/* <!-- Left column, wraps below col-md --> */}
            <div className="col-12 col-md-6 pl-0">
              <ul className="list-inline">
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/help/" className="d-print-none">Help</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/legal/copyright/">Copyright</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/legal/disclaimer/">Disclaimer</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/legal/privacy/">Privacy</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/right-to-information/">Right to information</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/help/accessibility/" className="d-print-none">Accessibility</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="http://www.smartjobs.qld.gov.au/" className="d-print-none">Jobs in Queensland Government</a></li>
                <li id="link-languages"><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/languages/" className="d-print-none">Other languages</a></li>
              </ul>
            </div>

            {/* <!-- Right column, wraps below col-md --> */}
            <div className="qg-copyright col-12 col-md-4 offset-md-2 pl-0 ml-0">
              &copy; The State of Queensland <span id="qg-copyright-daterange"></span>
            </div>

          </div>

        </div>

      </footer>
    </>
  );
};

export default Footer;
