import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../../constants";
import { useEffect, useRef } from "react";
import CriticalAlert from '../../components/swe/critical-alert';

export const NoEligibleCustomer = () => {

    const alertRef = useRef(null);
    const { logout } = useAuth0();

    useEffect(() => {
        alertRef?.current?.focus();
    }, []);

    useEffect(() => {
        (async () => {
            if (Constants.DEBUG) { console.log('Document is ineligible, hard stop! Uplift process terminated. Logging user out of QDI session.'); }

            logout({
                localOnly: true,
            });

            localStorage.clear();
            sessionStorage.clear();
        })();
    }, []);

    return (<>
        <div style={{ margin: "0 auto" }}>

            <h1>Queensland Digital Identity</h1>

            <div tabIndex={-1} ref={alertRef}>
                <CriticalAlert heading="Document is ineligible."
                    message={
                        <>
                            <p>The document you are attempting to verify is ineligible.</p>
                            <p>If you believe this to be incorrect, <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                        </>
                    }
                />
            </div>

        </div>
    </>)
};

export default NoEligibleCustomer;
