import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

import history from "./utils/history";
import useGlobalState from "./hooks/useGlobalState";
import ErrorBoundary from "./components/error/ErrorBoundary";

import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";

import Loading from "./components/Loading";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Aside from "./components/Aside";

import Logout from "./views/Logout";
import Home from "./views/Home";
import TermsAndConditions from "./views/TermsAndConditions";

import TryAgain from "./views/TryAgain";
import TooManyAttempts from "./views/TooManyAttempts";
import TooManyResendAttempts from "./views/TooManyResendAttempts";
import RetryLimitReachedPage from "./views/RetryLimitReached";
import ServiceUnavailable from "./views/ServiceUnavailable";
import NotFound from "./views/NotFound";
import ChangeEmail from "./views/ChangeEmail";
import EmailAlreadyUsed from "./views/EmailAlreadyUsed";
import EmailOTP from "./views/EnterEmailOneTimeCode";
import SessionTimeout from "./views/SessionTimeout";

import IpUplift from "./views/IpUplift";
import VerifyYourIdentity from "./views/VerifyYourIdentity";
import InterstateDLSelection from "./views/identity-docs/InterstateDLSelection";
import DriversLicenceForm from "./views/identity-docs/DriversLicenceForm";
import PhotoIdForm from "./views/identity-docs/PhotoIdForm";
import NoEligibleCustomer from "./views/identity-docs/NoEligibleCustomer";
import MarineLicenceForm from "./views/identity-docs/MarineLicenceForm";
import InterstateDriveLicencePage from "./views/identity-docs/InterstateDriversLicence/InterstateDriversLicencePage";
import SelectOtherDocument from "./views/SelectOtherDocument";
import PassportForm from "./views/identity-docs/PassportForm";
import BirthCertificateForm from "./views/identity-docs/BirthCertificateForm";
import MedicareCardSelectionPage from "./views/identity-docs/MedicareCardSelectionPage";
import MedicareCardForm from "./views/identity-docs/MedicareCardForm";
import VisaForm from "./views/identity-docs/VisaForm";
import MarriageCertificate from "./views/name-change-docs/MarriageCertificate";
import NamesDontMatch from "./views/name-change-docs/NamesDontMatch";
import CitizenshipCertificateForm from "./views/identity-docs/CitizenshipCertificateForm";
import ImmiCardForm from "./views/identity-docs/ImmCardForm";
import NameChangeOptions from "./views/NameChangeOptions";
import ChangeOfNameCertificate from "./views/name-change-docs/ChangeOfNameCertificate";
import * as Constants from "./constants";
import Success from "./views/Success";
import RelyingPartyAndServiceStatusCheck from "./components/RelyingPartyAndServiceStatusCheck";
import Unauthorized from "./views/Unauthorized";
import RequirementsNotMet from "./views/RequirementsNotMet";
import FamilyNameNotMatchHardStop from "./views/FamilyNameNotMatchHardStop";
import DOBNotMatchHardStop from "./views/DOBNotMatchHardStop";
import PWReset from "./views/pwreset";

initFontAwesome();

const App = () => {
  const { isLoading, error, logout } = useAuth0();
  const { globalState } = useGlobalState();
  const referringUrl = sessionStorage.getItem('QDI-RP') ? sessionStorage.getItem('QDI-RP') : document.referrer;

  useEffect(() => {
    if (Constants.DEBUG) { console.log('Welcome to uplift. The referring url is', referringUrl); }

    // ensures no existing logged in user upon loading of app
    logout({ localOnly: true, });

    sessionStorage.setItem('QDI-RP', referringUrl);

  }, [])

  if (error) {
    console.error(error);
  }

  if (isLoading) {
    return <Loading maxheight={true} />;
  }

  return (
    <>
      <Router history={history}>
        <Header />
        <RelyingPartyAndServiceStatusCheck />

        <div className="container-fluid qg-site-width">
          <div id="qg-content">
            <div id="qg-three-col" className="row">
              <div id="qg-primary-content" role="main">
                {(error && <div>Oops... {error.message}</div>)
                  ||
                  (<ErrorBoundary globalState={globalState}>
                    <Routes>

                      {/* <Route path="/" element={<Home />} />}
                      <Route path="/signup" element={<Home />} />*/}

                      <Route path="/logout" element={<Logout />} />
                      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

                      <Route path="/callback-ip1" element={<IpUplift />} />

                      <Route path="/change-email" element={<ChangeEmail />} />
                      <Route path="/email-error" element={<EmailAlreadyUsed />} />
                      <Route path="/service-unavailable" element={<ServiceUnavailable />} />
                      <Route path="/pwreset" element={<PWReset />} />

                      <Route path="/ip-uplift" element={<IpUplift />} />
                      <Route path="/ip-uplift/enter-otp" element={<EmailOTP />} />
                      <Route path="/ip-uplift/email-changed" element={<EmailOTP />} />

                      <Route path="/ip-uplift/verify-your-identity" element={<VerifyYourIdentity />} />
                      <Route path="/ip-uplift/verify-your-identity/interstate-dl-selection" element={<InterstateDLSelection />} />
                      <Route path="/ip-uplift/verify-your-identity/drivers-licence-form" element={<DriversLicenceForm />} />
                      <Route path="/ip-uplift/verify-your-identity/photo-id-form" element={<PhotoIdForm />} />
                      <Route path="/ip-uplift/verify-your-identity/marine-licence-form" element={<MarineLicenceForm />} />
                      <Route path="/ip-uplift/verify-your-identity/passport-form" element={<PassportForm />} />
                      <Route path="/ip-uplift/verify-your-identity/interstate-dl-selection/interstate-drivers-licence-form" element={<InterstateDriveLicencePage/>} />
                      <Route path="/ip-uplift/verify-your-identity/visa-form" element={<VisaForm />} />
                      <Route path="/ip-uplift/verify-your-identity/citizenship-certificate-form" element={<CitizenshipCertificateForm />} />
                      <Route path="/ip-uplift/verify-your-identity/immicard-form" element={<ImmiCardForm />} />
                      <Route path="/ip-uplift/verify-your-identity/birth-certificate-form" element={<BirthCertificateForm />} />
                      <Route path="/ip-uplift/verify-your-identity/medicare-card-type" element={<MedicareCardSelectionPage />} />
                      <Route path="/ip-uplift/verify-your-identity/medicare-card-form" element={<MedicareCardForm />} />

                      <Route path="/ip-uplift/verify-your-identity/no-eligible-customer" element={<NoEligibleCustomer />} />

                      <Route path="/ip-uplift/name-change-options" element={<NameChangeOptions />} />
                      <Route path="/ip-uplift/name-change-options/marriage-certificate-form" element={<MarriageCertificate />} />
                      <Route path="/ip-uplift/name-change-options/change-of-name-certificate-form" element={<ChangeOfNameCertificate />} />
                      <Route path="/ip-uplift/names-dont-match" element={<NamesDontMatch />} />
                      <Route path="/ip-uplift/too-many-attempts" element={<TooManyAttempts />} />
                      {/* <Route path="/ip-uplift/retry-limit-reached" element={<RetryLimitReachedPage />} /> */}
                      <Route path="/ip-uplift/too-many-resend-attempts" element={<TooManyResendAttempts />} />
                      <Route path="/ip-uplift/names-not-match-hardstop" element={<FamilyNameNotMatchHardStop />} />
                      <Route path="/ip-uplift/dob-not-match-hardstop" element={<DOBNotMatchHardStop />} />

                      <Route path="/ip-uplift/success" element={<Success/>} />

                      <Route path="/try-again" element={<TryAgain />} />
                      <Route path="/session-timeout" element={<SessionTimeout />} />
                      <Route path="/unauthorised" element={<Unauthorized />} />
                      <Route path="/signup-paused" element={<RequirementsNotMet />} />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </ErrorBoundary>)
                }
              </div>

              <Aside />

            </div>
          </div>
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;