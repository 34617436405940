import InfoAlert from "../components/swe/info-alert";

const PWReset = () => {

    return (
        <div style={{ marginBottom: "75px" }}>
            <h1>Queensland Digital Identity</h1>
            <InfoAlert message={
                <>
                    <p>To continue you will need to return to the agency, service or application that you were attempting to use.</p>
                    <p>If you have successfully changed your password, you will need to use your new password next time you log in to the Queensland Digital Identity.</p>
                </>
            } />

        </div>
    )
};

export default PWReset;