import React, { useState, useEffect, useRef } from "react";
import FormTypeProps from "./Form-type";
import VerifyYourIdentityConsentAlert from "../alerts/verifying-identity-consent-alert";
import PrimaryButton from "../../swe/primary-button";
import SecondaryButton from "../../swe/secondary-button";
import { useNavigate } from "react-router-dom";
import InfoAlert from "../../swe/info-alert";
import MedicareNumberInput from "../../swe/medicare-number-input";
import IndividualReferenceNumberInput from "../../swe/individual-reference-number-input";
import LabelRadio from "../../swe/base/label-radio";
import LabelInput from "../../swe/base/label-input";
import LabelDateInput from "../../swe/base/label-date-input";
import { CustomValidator, MandatoryValidator, MaximumLengthValidator, NonSpaceInputValidator, PatternValidator } from "../../../utils/validation/validator";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../../../constants';
import { AssociateQDIUserAccountBuilder, DocumentAlreadyVerifiedError, DocumentVerificationError, UnableToContactIssuingAgencyError, XBody } from "../../../services/x-api";
import { formatDateString, updateLocalStorageSessionTokenIpnAndStatus, updateSessionTokenIpn } from "../../../utils/helpers";
import DocumentVerificationFailedAlert from "../alerts/document-verification-failed-alert";
import DocumentAlreadyVerifiedAlert from "../alerts/document-already-verified-alert";
import DocumentInvalidAlert from "../alerts/document-invalid-alert";
import DoBMismatchAlert from "../alerts/dob-mismatch-alert";
import UnableToContactIssuingAgencyAlert from "../alerts/unable-to-contact-issuing-agency";
import useGlobalState from "../../../hooks/useGlobalState";

interface MedicareCardTypeProps extends FormTypeProps {
    selectedMedicareCardType: string
}

export const MedicareForm = (props: MedicareCardTypeProps) => {
    const navigate = useNavigate();
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const focusRef = useRef(null);

    const [medicareCardNumber, setMedicareCardNumber] = useState('');
    const [medicareCardNumberValid, setMedicareCardNumberValid] = useState(true);
    const [individualRefNumber, setIndividualRefNumber] = useState('');
    const [individualRefNumberValid, setIndividualRefNumberValid] = useState(true);
    const [nameLine1, setNameLine1] = useState('');
    const [nameLine1Valid, setNameLine1Valid] = useState(true);
    const [nameLine1Hint, setNameLine1Hint] = useState('');
    const [nameLine2, setNameLine2] = useState('');
    const [nameLine2Valid, setNameLine2Valid] = useState(true);
    const [nameLine2Hint, setNameLine2Hint] = useState('');
    const [nameLine3, setNameLine3] = useState('');
    const [nameLine3Valid, setNameLine3Valid] = useState(true);
    const [nameLine3Hint, setNameLine3Hint] = useState('');
    const [nameLine4, setNameLine4] = useState('');
    const [nameLine4Valid, setNameLine4Valid] = useState(true);
    const [expiryDate, setExpiryDate] = useState('');
    const [expiryDateValid, setExpiryDateValid] = useState(true);
    const [familyName, setFamilyName] = useState('');
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [familyNameHint, setFamilyNameHint] = useState('');
    const [isNameMultilined, setIsNameMultilined] = useState('FALSE');    
    const [isContinueDisabled, setIsContinueDisabled] = useState(true);
    const [counter, setCounter] = useState(0);
    //const [issuerNotReachableCounter, setIssuerNotReachableCounter] = useState(0);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const [dobMismatch, setDobMismatch] = useState(false);
    const [unableToContact, setUnableToContact] = useState(false);
    const [unexpectedError, setUnexpectedError] = useState(false);

    const YES_NO = new Map<string, string>([
        ['TRUE', 'Yes'],
        ['FALSE', 'No'],
    ]);

    let mandatoryValidator = new MandatoryValidator();
    let mdNumberMandatoryValidator = new CustomValidator((value: string) => { return value !== "" && value !== "--" }, 'Medicare card Number is required');
    let medicareNumberLengthValidator = new CustomValidator((value: string) => { return value.replaceAll('-', '').length === 10; }, "Medicare card Number must be 10 numbers");
    let individualRefNumberValidator = new CustomValidator((value: string) => { return +value >= 1 && +value <= 9; }, "Individual reference number must be between 1 and 9");

    let nameLine1MandatoryValidator = new CustomValidator((value: string) => { return value !== "" }, 'Name line 1 is required');
    let nl1AllSpacesValidator = new CustomValidator((value: string) => { return value.trim().length > 0 || value.length === 0; }, "Name line 1 cannot be all spaces"); // need custom validators to display lable as Name line 1
    let nl2AllSpacesValidator = new CustomValidator((value: string) => { return value.trim().length > 0 || value.length === 0; }, "Name line 2 cannot be all spaces");
    let nl3AllSpacesValidator = new CustomValidator((value: string) => { return value.trim().length > 0 || value.length === 0; }, "Name line 3 cannot be all spaces");
    let nl4AllSpacesValidator = new CustomValidator((value: string) => { return value.trim().length > 0 || value.length === 0; }, "Name line 4 cannot be all spaces");
    let nameLine1LengthValidator = new CustomValidator((value: string) => { return value.length <= 27; }, "Name line 1 cannot be longer than 27 characters");
    let nameLine2LengthValidator = new CustomValidator((value: string) => { return value.length <= 25; }, "Name line 2 cannot be longer than 25 characters");
    let nameLine3LengthValidator = new CustomValidator((value: string) => { return value.length <= 23; }, "Name line 3 cannot be longer than 23 characters");
    let nameLine4LengthValidator = new CustomValidator((value: string) => { return value.length <= 21; }, "Name line 4 cannot be longer than 21 characters");
    let namePatternValidator = new PatternValidator(/[^a-zA-Z0-9'’\- ]/, "Only the following special characters and symbols are allowed ' -");
    let nl2MandatoryWhenNL3AOrNL4PresentValidator = new CustomValidator((value: string) => { return !(value.length === 0 && (nameLine3.length > 0 || nameLine4.length > 0)); }, "Name line 2 is required");
    let nl3MandatoryWhenNL4PresentValidator = new CustomValidator((value: string) => { return !(value.length === 0 && nameLine4.length > 0); }, "Name line 3 is required");
    let datePartValidator = new CustomValidator((value: string) => { return props.selectedMedicareCardType === 'G' ? value.length >= 8 : value.length >= 10; }, "Expiry date is invalid");
    let invalidDateValidator = new CustomValidator((value: string) => { 
        let valid = true;
        let dateParts = value.split('-');
        let month = Number(dateParts[1]); //yyyy-MM-dd
        let day = Number(dateParts[2]);
        let year = Number(dateParts[0]);

        if (day > 0 && month > 0 && year > 0) { // validate for dd/mm/yyyy
            let checkIsActualDate = new Date(year,month-1,day);            
            if (month > 12 || month === 0 || day === 0 || day > 31 || (month-1) !== checkIsActualDate.getMonth()) {
                valid = false;
            }
            else if (isNaN(checkIsActualDate.getTime())) {
                valid = false;
            }
        } else if (month > 0 && year > 0) { // validate for mm/yyyy
            if (month > 12 || month === 0) {
                valid = false;
            }
        }
        return valid;
    }, "Expiry date is invalid");
    let notPastDateValidator = new CustomValidator((value: string) => {
        let currentMonth = (new Date()).getMonth() + 1;
        if(+value.split('-')[0] > (new Date()).getFullYear()) {
            return true;
        } else if (+value.split('-')[0] === (new Date()).getFullYear()) {
            if(+value.split('-')[1] > currentMonth) {
                return true;
            } else if (+value.split('-')[1] === currentMonth) {
                if(props.selectedMedicareCardType === 'G' ||  +value.split('-')[2] >= (new Date()).getDate()) {
                    return true;
                }
            }
        }       
        return false;
    }, "Expiry date cannot be a past date");
    let allSpacesValidator = new NonSpaceInputValidator();
    let fnLengthValidator = new MaximumLengthValidator(27);
    let familyNameExistsInFullNameValidator = new CustomValidator((value: string) => {
        let fullName = (" " + nameLine1 + " " + nameLine2 + " " + nameLine3 + " " + nameLine4 + " ").toUpperCase();
        let familyNameToCheck = " " + value.trim().toUpperCase() + " ";
        return fullName.includes(familyNameToCheck);
    }, "Family name must match the family name entered in the Full name field/s");

    const checkFamilyNameInFullName = (fullName: string) => {
        // // In order to check family name exists in 4 name lines, we concatinate name lines with a space in between.
        // // So even family name has multiple parts seperated with spaces, it will give a match when we check with string.inclueds()
        let familyNameToCheck = " " + familyName.trim() + " ";
        if(fullName.includes(familyNameToCheck)) {
            setFamilyNameValid(true);
            setFamilyNameHint('');
        } else if(familyName.length > 0) {
            setFamilyNameValid(false);
            setFamilyNameHint('Family name must match the family name entered in the Full name field/s');
        }
        return true;
    }

    let familyNameExistsInFullNameCheckWhenName1ChangedValidator = new CustomValidator((value: string) => {
        return checkFamilyNameInFullName(" " + value + " " + nameLine2 + " " + nameLine3 + " " + nameLine4 + " "); }, "");
    let familyNameExistsInFullNameCheckWhenName2ChangedValidator = new CustomValidator((value: string) => {
        return checkFamilyNameInFullName(" " + nameLine1 + " " + value + " " + nameLine3 + " " + nameLine4 + " "); }, "");
    let familyNameExistsInFullNameCheckWhenName3ChangedValidator = new CustomValidator((value: string) => {
        return checkFamilyNameInFullName(" " + nameLine1 + " " + nameLine2 + " " + value + " " + nameLine4 + " "); }, "");
    let familyNameExistsInFullNameCheckWhenName4ChangedValidator = new CustomValidator((value: string) => {
        return checkFamilyNameInFullName(" " + nameLine1 + " " + nameLine2 + " " + nameLine3 + " " + value + " "); }, "");

    useEffect(() => {
        if(nameLine1.length === 0 && (nameLine2.length > 0 || nameLine3.length > 0 || nameLine4.length > 0 || familyName.length > 0)) {
            setNameLine1Valid(false);
            setNameLine1Hint("Name line 1 is required");
        }

        if(nameLine2.length === 0 && (nameLine3.length > 0 || nameLine4.length > 0)) {
            setNameLine2Valid(false);
            setNameLine2Hint("Name line 2 is required");
        } else if (nameLine2Hint === "Name line 2 is required") {
            setNameLine2Valid(true);
            setNameLine2Hint("");
        }
        
        if(nameLine3.length === 0 && (nameLine4.length > 0)) {
            setNameLine3Valid(false);
            setNameLine3Hint("Name line 3 is required");
        } else if (nameLine3Hint === "Name line 3 is required") {
            setNameLine3Valid(true);
            setNameLine3Hint("");
        }
    }, [nameLine1, nameLine2, nameLine3, nameLine4, familyName]);

    useEffect(() => {
        if(isNameMultilined === 'FALSE') {
            setNameLine2("");
            setNameLine2Valid(true);
            setNameLine2Hint("");
            setNameLine3("");
            setNameLine3Valid(true);
            setNameLine3Hint("");
            setNameLine4("");
            setNameLine4Valid(true);
            checkFamilyNameInFullName(" " + nameLine1 + " ");
        }
    }, [isNameMultilined]);
    
    const validateIsContinueDisabled = () => {
        let areAllFieldsValid = (medicareCardNumberValid && individualRefNumberValid && nameLine1Valid && nameLine2Valid && nameLine3Valid && nameLine4Valid && familyNameValid && expiryDateValid);
        let areAllMandatoryFieldsPopulated = (medicareCardNumber.length > 2 && individualRefNumber.length > 0 && nameLine1.length > 0 && familyName.length > 0 && expiryDate.length);

        return (!areAllFieldsValid || !areAllMandatoryFieldsPopulated);
    }

    useEffect(() => {
        const isInvalid = validateIsContinueDisabled();
        setIsContinueDisabled(isInvalid);
    }, [medicareCardNumber, individualRefNumber, nameLine1, nameLine2, nameLine3, nameLine4, familyName, expiryDate]);

    useEffect(() => {
        focusRef?.current?.focus();
    }, [verificationFailed, documentAlreadyVerified, documentInvalid, dobMismatch, unableToContact, unexpectedError]);

    const navigateBackTo = () => {
        navigate(props.backNavigateTo, { replace: true, state: {ipnLevel: props.ipnLevel} });
    }

    const setAlertVisibility = (verifyFailed: boolean, docInvalid: boolean, docAlreadyVerified: boolean, dobMismtch: boolean, unableToConnect: boolean) => {    
      setVerificationFailed(verifyFailed);
      setDocumentInvalid(docInvalid);
      setDocumentAlreadyVerified(docAlreadyVerified);
      setDobMismatch(dobMismtch);
      //setUnableToContact(unableToConnect)
    }

    const verifyMedicareDetails = async () => {
        setAlertVisibility(false, false, false, false, false); //Reset alert visibility
        
        try {
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims(); 
            console.group('async verifyMedicareDetails');
            if(Constants.DEBUG) { console.log(token, idToken); }

            let builder = new AssociateQDIUserAccountBuilder()
                .withUserId(idToken!.sub)
                .withFamilyName(familyName.trim())
                .withVerifiedDocTypeCode("MD")
                .withVerifiedDocIdentifier("Card Number", medicareCardNumber.replaceAll('-', ''))
                .withVerifiedDocIdentifier("Individual Ref Number", individualRefNumber)
                .withVerifiedDocAttribute("Card Type", props.selectedMedicareCardType)
                .withVerifiedDocAttribute("Card Expiry", formatDateString(expiryDate))
                .withVerifiedDocAttribute("Full Name 1", nameLine1.trim());

            if (nameLine2) builder.withVerifiedDocAttribute("Full Name 2", nameLine2.trim());
            if (nameLine3) builder.withVerifiedDocAttribute("Full Name 3", nameLine3.trim());
            if (nameLine4) builder.withVerifiedDocAttribute("Full Name 4", nameLine4.trim());

            let certificate = new XBody(builder.build());

            await props.action(certificate, token);
            setCounter(0);
            //setIssuerNotReachableCounter(0);

            // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
            // ipn_status not getting updated in token issue.
            const updatedSessionToken = updateSessionTokenIpn('ip2');
            saveGlobalState({ sessionToken: updatedSessionToken });
            
            navigate("/ip-uplift", { replace: true });
        } catch (error) {
            if(counter >= 4) { // fail after 5 attempts
                navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
            // } else if(issuerNotReachableCounter > 4) {
            //     navigate("/ip-uplift/retry-limit-reached?returnCode=8", { replace: true });
            }
            setCounter(counter + 1);
            if (Constants.DEBUG) { console.log(error); }
            if (error instanceof DocumentVerificationError) {
                if (error.failureCode === "N" && error.failureMessage === "Date of birth not match") {
                    setAlertVisibility(false, false, false, true, false);
                }
                else if (error.failureCode === "N" && error.failureMessage === "Name not match") {
                    // Setting localStorage.sessionToken.ipn = "ip1p_nc" as a temp fix for
                    // ipn_status not getting updated in token issue.
                    updateLocalStorageSessionTokenIpnAndStatus("ip1p_nc", "nc");
                    navigate("/ip-uplift/name-change-options", { replace: true });
                }
                else if (error.failureCode === "D") {
                    setAlertVisibility(false, true, false, false, false)
                } else if (error.failureMessage === "Document Temporarily Locked") {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                } else if (error.failureCode === "N"){
                    setAlertVisibility(true, false, false, false, false);
                } else {
                    setUnexpectedError(true);
                    throw error;
                }
            } else if (error instanceof DocumentAlreadyVerifiedError) {
                setAlertVisibility(false, false, true, false, false);
            } 
            // This error message will be enabled in future. Commenting it for now to keep consistency across all documents.
            // else if (error instanceof UnableToContactIssuingAgencyError) {  
            //     setIssuerNotReachableCounter(issuerNotReachableCounter + 1);                          
            //     setAlertVisibility(false, false, false, false, true);
            // } 
            else {
                setUnexpectedError(true);
                throw error;
            }
        } finally {
            console.groupEnd();
        }
    }

    return (
        <>
            <div tabIndex={-1} ref={focusRef}></div>
            {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel={props.id} /> : null }
            {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null }
            {documentInvalid ? <DocumentInvalidAlert /> : null }
            {dobMismatch ? <DoBMismatchAlert /> : null }
            {/* {unableToContact ? <UnableToContactIssuingAgencyAlert /> : null } */}
            {props.instructions}

            <section className="row cards-identity qg-cards cards_equal-height">
                {props.product}
            </section>
            
            <form className="qg-forms-v2">
                <ol className="questions">
                    <li>
                        <MedicareNumberInput 
                            id="medicare-card-number"
                            inputValue={medicareCardNumber}
                            setInputValue={setMedicareCardNumber}
                            isInputValid={medicareCardNumberValid}
                            setInputValid={setMedicareCardNumberValid}
                            label="Medicare card Number"
                            validators={[mdNumberMandatoryValidator, medicareNumberLengthValidator]}
                        />
                    </li>
                    <li>                        
                        <IndividualReferenceNumberInput
                            id="individual-ref-number"
                            inputValue={individualRefNumber}
                            setInputValue={setIndividualRefNumber}
                            isInputValid={individualRefNumberValid}
                            setInputValid={setIndividualRefNumberValid}
                            label="Individual reference number"
                            validators={[mandatoryValidator, individualRefNumberValidator]}
                        />
                    </li>
                    <li>
                        <LabelRadio
                            id="is-name-multiline"
                            label="Does your name appear across more than one line of the card?"
                            inputValue={isNameMultilined}
                            setInputValue={setIsNameMultilined}
                            options={YES_NO}
                        />
                    </li> 
                    <li>
                        <LabelInput 
                            id="name-line-1"
                            inputValue={nameLine1}
                            setInputValue={setNameLine1}
                            isInputValid={nameLine1Valid}
                            setInputValid={setNameLine1Valid}
                            inputHint={nameLine1Hint}
                            setInputHint={setNameLine1Hint}
                            label="Full name"
                            persistentHint="Full name must be entered as it appears on your Medicare card."
                            placeholder="Name line 1"
                            validators={[
                                nameLine1MandatoryValidator, 
                                nl1AllSpacesValidator,
                                nameLine1LengthValidator, 
                                namePatternValidator, 
                                familyNameExistsInFullNameCheckWhenName1ChangedValidator
                            ]}
                        />
                    </li>
                    {isNameMultilined === 'TRUE' && 
                        <>
                            <li>
                                <LabelInput 
                                    id="name-line-2"
                                    inputValue={nameLine2}
                                    setInputValue={setNameLine2}
                                    isInputValid={nameLine2Valid}
                                    setInputValid={setNameLine2Valid}
                                    inputHint={nameLine2Hint}
                                    setInputHint={setNameLine2Hint}
                                    label=""
                                    placeholder="Name line 2"
                                    mandatory={false}
                                    validators={[
                                        nl2MandatoryWhenNL3AOrNL4PresentValidator, 
                                        nl2AllSpacesValidator,
                                        nameLine2LengthValidator, 
                                        namePatternValidator,
                                        familyNameExistsInFullNameCheckWhenName2ChangedValidator
                                    ]}
                                />
                            </li>  
                            <li>
                                <LabelInput 
                                    id="name-line-3"
                                    inputValue={nameLine3}
                                    setInputValue={setNameLine3}
                                    isInputValid={nameLine3Valid}
                                    setInputValid={setNameLine3Valid}
                                    inputHint={nameLine3Hint}
                                    setInputHint={setNameLine3Hint}
                                    label=""
                                    placeholder="Name line 3"
                                    mandatory={false}
                                    validators={[
                                        nl3MandatoryWhenNL4PresentValidator, 
                                        nl3AllSpacesValidator,
                                        nameLine3LengthValidator, 
                                        namePatternValidator,
                                        familyNameExistsInFullNameCheckWhenName3ChangedValidator
                                    ]}
                                />
                            </li> 
                            <li>
                                <LabelInput 
                                    id="name-line-4"
                                    inputValue={nameLine4}
                                    setInputValue={setNameLine4}
                                    isInputValid={nameLine4Valid}
                                    setInputValid={setNameLine4Valid}
                                    label=""
                                    placeholder="Name line 4"
                                    mandatory={false}
                                    validators={[
                                        nl4AllSpacesValidator,
                                        nameLine4LengthValidator, 
                                        namePatternValidator,
                                        familyNameExistsInFullNameCheckWhenName4ChangedValidator
                                    ]}
                                />
                            </li> 
                        </>
                    }
                    <li>
                        <LabelDateInput
                            id="expiry-date"
                            label="Expiry date"
                            inputValue={expiryDate}
                            setInputValue={setExpiryDate}
                            isInputValid={expiryDateValid}
                            setInputValid={setExpiryDateValid}
                            hideDayPart={props.selectedMedicareCardType === 'G'}
                            validators={[mandatoryValidator, datePartValidator, invalidDateValidator, notPastDateValidator]}
                        />
                    </li>    
                    <li>
                        <LabelInput 
                            id="family-name"
                            inputValue={familyName}
                            setInputValue={setFamilyName}
                            isInputValid={familyNameValid}
                            setInputValid={setFamilyNameValid}
                            label="Family name"
                            persistentHint="Confirm Family name"
                            validators={[
                                mandatoryValidator, 
                                allSpacesValidator, 
                                fnLengthValidator, 
                                namePatternValidator, 
                                familyNameExistsInFullNameValidator
                            ]}
                        />
                    </li>
                </ol>
            </form>

            <VerifyYourIdentityConsentAlert />

            <div className="my-3">
                <ol className="questions">
                    <li>
                        <ul className='actions'>
                            <PrimaryButton id="submitButton" heading="Continue" action={verifyMedicareDetails} disabled={isContinueDisabled} />
                            <SecondaryButton id="backButton" heading="Back" action={navigateBackTo} />
                        </ul>
                    </li>
                </ol>
            </div>
        </>
    );
}

export default MedicareForm;