import { useEffect, useRef } from 'react';
import CriticalAlert from "../components/swe/critical-alert";
import * as Constants from "../constants"
import { useSearchParams } from "react-router-dom";

const ServiceUnavailable = () => {
    const alertRef = useRef(null);
    const [searchParams] = useSearchParams();
    let message = searchParams.get("message");
    message = (message && message !== 'undefined') ? message : "The Queensland Government apologise for any inconvenience.";

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    return (
        <>
            <div>
                <h1>Queensland Digital Identity</h1>
                <div>
                    <CriticalAlert 
                        heading="Service unavailable"
                        message={
                        <> 
                            <p>Queensland Digital Identity is currently unavailable. {message}</p>
                            <p>If you require immediate assistance please <a href={Constants.CONTACT_US_URL}>contact us</a>.</p>                                
                        </>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default ServiceUnavailable;