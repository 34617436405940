import WarningAlert from "../../swe/warning-alert";
import * as Constants from '../../../constants';
/**
 * Orange alert which informs that the user's submitted document is valid but
 * the date of birth does not match with the previous documents.
 * 
 * Used for third documents, DL users only
 */

export const ThirdDocDoBMismatchAlert = () => {
    return (
        <WarningAlert
            heading=""
            message={<>
                <p>All of your credentials have successfully been verified, however your date of birth is different from your other documents and does not match.</p>
                <p>You can:</p>
                <ul>
                    <li>re-enter your information</li>
                    <li>go back and select another document</li>
                    <li><a href={Constants.CONTACT_US_URL}>contact us</a> for further assistance</li>
                </ul>
            </>}
        />
    );
}

export default ThirdDocDoBMismatchAlert;