import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";
import useGlobalState from "../hooks/useGlobalState";
import { extractIpn, determineCorrectIpnValue } from "../utils/helpers";

import "../assets/css/qdi-portal.css";
import SecondaryButton from "../components/swe/secondary-button";
import CriticalAlert from '../components/swe/critical-alert';
import SuccessAlert from "../components/swe/success-alert";
import ProductList from "../components/qdi/product-list";
import { getDocList } from "../services/x-api";
import Loading from "../components/Loading";

/**
 * Displays the VerifyYourIdentity page and navigates to the user selected TMR product page
 * @returns the page
 */
export function VerifyYourIdentity() {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);
    var verificationFailed = globalState.verificationFailed;
    const { state } = useLocation();
    const ipnLevel = state && state.ipnLevel;
    const dlaUser = state && state.dlaUser;
    const [docList, setDocList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }

                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const claimsIpnFromAcr = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
                const ipn = determineCorrectIpnValue(claimsIpnFromAcr, user['http://oauth.tmr.qld.gov.au'].ipn);
                
                if (Constants.DEBUG) {
                    console.log('users ipn ->', ipn);
                    console.log('%c[VerifyYourIdentity] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here - The following conditions mean they SHOULD NOT
                if (typeof globalState.userCompletedMFA === 'undefined'
                    || (user && user.email_verified === false)
                        || (!dlaUser && globalState.sessionToken.ipn !== ipnLevel)
                            || (dlaUser && (dlaUser.result === 'Eligible' || dlaUser.result === 'Not eligible')) ) {

                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                // if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                // window.location.href = rp;
                navigate("/unauthorised", { replace: true });
            }
        })();
    }, []);

    useEffect(() => {
        focusRef?.current?.focus();
    }, []);

    const [cancelled, setCancelled] = useState(false);

    async function cancelClicked(event) {
        if (cancelled) {
            navigate("/signup-paused", { replace: true });
        }
        setCancelled(true);
    }
    
    useEffect(()=> {
        const getDocListToDisplay = async () => {
            try {    
                //setIsLoading(true);
                const token = await getAccessTokenSilently({ ignoreCache: true });
                const idToken = await getIdTokenClaims();

                const response = await getDocList(idToken.sub, ipnLevel, dlaUser, token);
                let docListWithoutDeletedDoc = response.docList;

                if(ipnLevel === "ip1p") {
                    const deletedDocType = localStorage.getItem("deleted-doc-type");
                    if (deletedDocType) {
                        if(deletedDocType === "DL") // If the deleted doc type is DL, that means the deleted document is an interstate DL - IDP-6074
                            docListWithoutDeletedDoc = response.docList.filter((doc) => doc !== "ISDL");
                        else
                            docListWithoutDeletedDoc = response.docList.filter((doc) => doc !== deletedDocType);
                    }
                }
                setDocList(docListWithoutDeletedDoc);
                setIsLoading(false);

                // reset the licence verification flag to false so as only display error alert, (IDP-4825), once
                saveGlobalState({ verificationFailed: false });

            } catch (error) {
                if (Constants.DEBUG) console.log('Error calling getDocList, %o', error);
                throw error;
            }
        }
        
        getDocListToDisplay();
    }, []);

    return (
        <>
            {isAuthenticated &&
                <div>
                    {/* <h1>Verify your {ipnLevel === "ip1" ? "first" : "second"} credential</h1>  */}

                    <h1>Verify your {ipnLevel === "ip1" ? "first" : ipnLevel === "ip1p" ? "second" : dlaUser && dlaUser.result === 'TMR document required' ? "TMR" : ""} credential</h1> 

                    <div tabIndex={-1} ref={focusRef}>
                        {ipnLevel === "ip1p" && !verificationFailed &&
                            <SuccessAlert message={<><p>You have successfully verified your first credential.</p><p>You will now need to verify a second credential.</p></>} />
                        }
                        {verificationFailed ? <CriticalAlert heading="Document is invalid" message={<p>The document you are attempting to verify is invalid. Select a <a href="https://www.qld.gov.au/transport/projects/digital-licence/queensland-digital-identity#Step5">valid document</a> from the list below.<br />If you believe this to be incorrect, <a href="https://www.qld.gov.au/contact-us">contact us</a> to continue setting up your Queensland Digital Identity.</p>} /> : null}
                    </div>

                    <div>
                        {ipnLevel === "ip1" && 
                            <>
                                <p> So we can confirm you are who you say you are, we will verify your identity using some forms of identification. </p>
                                <p> You will only need to complete this process one time. Once you have successfully verified your identity, you will not have to do so again. </p>
                            </>
                        }
                        {ipnLevel === "ip1p" &&  
                            <p> <strong>Please select one credential that you have from the list below:</strong> </p>
                        }
                        {dlaUser && dlaUser.result === 'TMR document required' &&
                            <>
                                <p>In order to access the Digital Licence app, we need to confirm the details from one of your Transport and Main Roads (TMR) credentials.</p>
                                <p>You will only need to complete this process one time. Once you have successfully verified your TMR credential, you will not have to do this again.</p>
                                <p><strong>Please select one credential that you have from the list below:</strong></p>
                            </>
                        }
                        {dlaUser && dlaUser.result === 'Third document required' &&
                            <>
                                <p>In order to access the Digital Licence app, we need to confirm the details from one of your credentials.</p>
                                <p>You will only need to complete this process one time. Once you have successfully verified your credential, you will not have to do this again.</p>
                                <p><strong>Please select one credential that you have from the list below:</strong></p>
                            </>
                        }           

                        <section className="row cards-identity qg-cards">
                            {isLoading ? 
                                <Loading /> :
                                <ProductList 
                                    docList={docList}
                                    ipnLevel={ipnLevel}
                                    dlaUser={dlaUser}
                                />
                            }
                        </section>

                        {cancelled &&
                            <div id='cancelAlert' className="alert alert-warning" role="alert">
                                <h2 className="alert-heading">
                                    <span className="fa fa-exclamation-triangle"></span>Sure you want to cancel?
                                </h2>
                                <div>
                                    <p> If you cancel, you will be returned to the service page, and your identity will not be verified. </p>
                                    <p>  You will be able to verify your identity later by logging in using your email and password, and continuing this process. </p>
                                    <p> Select Cancel if you wish to cancel this process. </p>
                                </div>
                            </div>
                        }
                        <ol className="questions">
                            <li className="footer">
                                <ul className="actions">
                                    <SecondaryButton id="cancelButton" heading="Cancel" action={cancelClicked} />
                                </ul>
                            </li>
                        </ol>

                    </div>
                </div>
            }
        </>)
}

export default VerifyYourIdentity;
