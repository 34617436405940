import React from "react";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from '../constants'
import SessionManager from "./SessionManager";

import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from 'react-idle-timer'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "./Breadcrumbs";

const Header = () => {
  const linkContactUs = Constants.CONTACT_US_URL;
  const { globalState, saveGlobalState } = useGlobalState();
  //const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { user, isAuthenticated, logout, buildLogoutUrl } = useAuth0();
  const { getRemainingTime } = useIdleTimer();

  let logoutWithRedirect = () => logout({ returnTo: [window.location.origin, 'logout'].join('/') });

  if (Constants.DEBUG) {
    console.info('DEBUG ✔', getRemainingTime() / 60000);
  }

  return (
    <>
      <SessionManager />

      <header id="qg-site-header" className="qg-site-header sticky" role="banner">

        <div className="container-fluid qg-site-width qg-site-header-top">
          <div className="row align-items-center justify-content-between">
            <button id="qg-show-menu" className="qg-show-menu" aria-label="Search" data-toggle="collapse" data-target=".qg-navigation" aria-expanded="false" aria-controls="qg-site-nav qg-portal-links">
              <div className="qg-global-icons ">
                <div className="qg-icon--menu-default">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="72" y="95" width="368" height="60" rx="15"></rect>
                      <rect x="72" y="226" width="368" height="59" rx="15"></rect>
                      <rect x="72" y="356" width="368" height="60" rx="15"></rect>
                    </g>
                  </svg>
                </div>
                <div className="qg-icon--menu-close">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect transform="translate(256.500000, 256.000000) scale(1, -1) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                      <rect transform="translate(256.500000, 256.000000) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                    </g>
                  </svg>
                </div>
              </div>
              <span>Menu</span>
            </button>

            <div className="qg-coat-of-arms">
              <a href="//www.qld.gov.au" tabIndex="0">
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-white.svg" alt="Queensland Government" className="hidden-xs" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked-white.svg" alt="Queensland Government" className="hidden-sm hidden-md hidden-lg" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked.svg" alt="Queensland Government" className="hidden-xs hidden-sm hidden-md hidden-lg qg-print-coat-of-arms" />
              </a>
            </div>

            <button id="qg-show-search" className="qg-show-search" aria-label="Search" data-toggle="collapse" data-target="#qg-global-search-form" aria-expanded="false">
              <div className="qg-global-icons ">
                <div className="qg-icon--mobile-search-default">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g transform="translate(67.298684, 71.201316)">
                        <path d="M108.7791,227.405285 L11.3079182,324.876467 C5.45005384,330.734332 5.45005384,340.231806 11.3079182,346.089671 L27.4103294,362.192082 C33.2681938,368.049946 42.7656685,368.049946 48.6235328,362.192082 L146.094715,264.7209 C151.952579,258.863035 151.952579,249.365561 146.094715,243.507696 L129.992304,227.405285 C124.134439,221.547421 114.636965,221.547421 108.7791,227.405285 Z"></path>
                        <path d="M221.701316,0.798683501 C305.096314,0.798683501 372.701316,68.6275439 372.701316,152.298684 C372.701316,235.969823 305.096314,303.798684 221.701316,303.798684 C138.306319,303.798684 70.7013165,235.969823 70.7013165,152.298684 C70.7013165,68.6275439 138.306319,0.798683501 221.701316,0.798683501 Z M221.310125,54.0389936 C167.081772,54.0389936 123.121006,98.2066236 123.121006,152.690156 C123.121006,207.173689 167.081772,251.341319 221.310125,251.341319 C275.538478,251.341319 319.499244,207.173689 319.499244,152.690156 C319.499244,98.2066236 275.538478,54.0389936 221.310125,54.0389936 Z" fillRule="nonzero" transform="translate(221.701316, 152.298684) scale(-1, 1) translate(-221.701316, -152.298684) "></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="qg-icon--menu-close">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect transform="translate(256.500000, 256.000000) scale(1, -1) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                      <rect transform="translate(256.500000, 256.000000) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                    </g>
                  </svg>
                </div>
              </div>
              <span>Search</span>
            </button>

            <form target="_blank" rel="noopener noreferrer" action="https://www.qld.gov.au/search" id="qg-global-search-form" role="search" className="qg-site-search__form qg-search-form collapse qg-global-web-autocomplete" data-suggestions="https://discover.search.qld.gov.au/s/suggest.json?collection=qgov~sp-search&fmt=json%2B%2B&alpha=0.5&profile=qld" data-results-url="https://discover.search.qld.gov.au/s/search.json?collection=qgov~sp-search&profile=qld&meta_sfinder_sand=yes">
              <div className="input-group">
                <label htmlFor="qg-search-query" className="qg-visually-hidden">Search Queensland Government</label>
                <input type="text" name="query" id="qg-search-query" className="form-control qg-search-site__input" autoComplete="off" placeholder="Search website" tabIndex="0" aria-expanded="false" role="combobox" />
                <svg className="qg-search__icon" width="512px" height="512px" viewBox="0 0 512 512">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(67.298684, 71.201316)">
                      <path d="M108.7791,227.405285 L11.3079182,324.876467 C5.45005384,330.734332 5.45005384,340.231806 11.3079182,346.089671 L27.4103294,362.192082 C33.2681938,368.049946 42.7656685,368.049946 48.6235328,362.192082 L146.094715,264.7209 C151.952579,258.863035 151.952579,249.365561 146.094715,243.507696 L129.992304,227.405285 C124.134439,221.547421 114.636965,221.547421 108.7791,227.405285 Z"></path>
                      <path d="M221.701316,0.798683501 C305.096314,0.798683501 372.701316,68.6275439 372.701316,152.298684 C372.701316,235.969823 305.096314,303.798684 221.701316,303.798684 C138.306319,303.798684 70.7013165,235.969823 70.7013165,152.298684 C70.7013165,68.6275439 138.306319,0.798683501 221.701316,0.798683501 Z M221.310125,54.0389936 C167.081772,54.0389936 123.121006,98.2066236 123.121006,152.690156 C123.121006,207.173689 167.081772,251.341319 221.310125,251.341319 C275.538478,251.341319 319.499244,207.173689 319.499244,152.690156 C319.499244,98.2066236 275.538478,54.0389936 221.310125,54.0389936 Z" fillRule="nonzero" transform="translate(221.701316, 152.298684) scale(-1, 1) translate(-221.701316, -152.298684) "></path>
                    </g>
                  </g>
                </svg>
                <button className="qg-search-close-concierge hide" type="button" aria-label="Close Search Concierge">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect transform="translate(256.500000, 256.000000) scale(1, -1) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                      <rect transform="translate(256.500000, 256.000000) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                    </g>
                  </svg>
                </button>
                <button type="submit" id="feature-search-submit" className="btn btn-global-primary" title="Search site" data-analytics-link-group="qg-global-search-submit">Search</button>
                <div className="qg-search-concierge qg-search-concierge-initial">
                  <div className="qg-search-concierge-group helper">
                    <div className="qg-search-concierge-content">
                      <h4>Popular services</h4>
                      <ul className="list-group">
                        <li className="list-group-item"><a href="https://www.service.transport.qld.gov.au/renewregistration/public/Welcome.xhtml?" tabIndex="-1" data-analytics-link-group="qg-global-search-popular-services">Renew vehicle or boat rego</a></li>
                        <li className="list-group-item"><a href="https://www.service.transport.qld.gov.au/checkmydemeritpoints/public/Welcome.xhtml?" tabIndex="-1" data-analytics-link-group="qg-global-search-popular-services">Check my licence demerit points</a></li>
                        <li className="list-group-item"><a href="https://www.qld.gov.au/transport/licensing/driver-licensing/renew-replace/renew/online" tabIndex="-1" data-analytics-link-group="qg-global-search-popular-services">Renew my driving licence</a></li>
                        <li className="list-group-item"><a href="https://www.change-of-address.services.qld.gov.au" tabIndex="-1" data-analytics-link-group="qg-global-search-popular-services">Change my address</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="qg-search-concierge-group helper">
                    <div className="qg-search-concierge-content">
                      <h4>Browse by category</h4>
                      <ul className="list-group">
                        <li className="list-group-item"><a tabIndex="-1" href="https://www.qld.gov.au/queenslanders/transport-and-motoring">Transport and motoring</a></li>
                        <li className="list-group-item"><a tabIndex="-1" href="https://www.qld.gov.au/queenslanders/employment-and-jobs">Employment and jobs</a></li>
                        <li className="list-group-item"><a tabIndex="-1" href="https://www.qld.gov.au/queenslanders/education-and-training">Education and training</a></li>
                        <li className="list-group-item"><a tabIndex="-1" href="https://www.qld.gov.au/queenslanders/queensland-and-its-government">Queensland and its government</a></li>
                      </ul>
                      <a href="https://www.qld.gov.au/queenslanders" tabIndex="-1" className="all" data-analytics-link-group="qg-global-search-category">Browse all categories</a>
                    </div>
                  </div>
                </div>
                <div className="qg-search-concierge qg-search-concierge-help">
                  <div className="qg-search-concierge-group suggestions">
                    <div className="qg-search-concierge-content">
                      <h4>Suggestions</h4>
                      <ul className="list-group">
                        <li className="list-group-item"><button tabIndex="-1" data-analytics-link-group="qg-global-search-suggestion"><b>canc</b>el registration for osconnect</button></li>
                        <li className="list-group-item"><button tabIndex="-1" data-analytics-link-group="qg-global-search-suggestion"><b>canc</b>el a booking</button></li>
                        <li className="list-group-item"><button tabIndex="-1" data-analytics-link-group="qg-global-search-suggestion"><b>canc</b>el vehicle or boat registration</button></li>
                      </ul>
                    </div>
                  </div>
                  <div className="qg-search-concierge-group highlight">
                    <div className="qg-search-concierge-content">
                      <div className="d-flex justify-content-between align-content-center flex-wrap">
                        <h4>Need to cancel your rego?</h4>
                        <div>
                          <span className="fa fa-car"></span>
                          <span className="fa fa-motorcycle"></span>
                          <span className="fa fa-anchor"></span>
                        </div>
                      </div>
                      <p>Save time and cancel your vehicle or boat registration online.</p>
                      <a href="#" tabIndex="-1" className="btn btn-global-primary-white" data-analytics-link-group="qg-global-search-feature">Cancel my registration</a>
                    </div>
                  </div>
                  <div className="qg-search-concierge-group helper">
                    <div className="qg-search-concierge-content">
                      <h4>Related services</h4>
                      <ul className="list-group">
                        <li className="list-group-item"><a tabIndex="-1" href="#" data-analytics-link-group="qg-global-search-related-service">Cancel a booking</a></li>
                        <li className="list-group-item"><a tabIndex="-1" href="#" data-analytics-link-group="qg-global-search-related-service">Cancel first aid training</a></li>
                        <li className="list-group-item"><a tabIndex="-1" href="#" data-analytics-link-group="qg-global-search-related-service">Cancel a disability parking permit</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--parameters to display results on search page--> */}
              <input type="hidden" name="collection" value="qgov~sp-search" />
              <input type="hidden" name="profile" value="qld" />
            </form>
          </div>
        </div>

        <div id="qg-portal-links" className="qg-portal-links qg-navigation collapse" aria-label="top">
          <div className="container-fluid qg-site-width">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse" id="qgPortalLinksDropdown">
                <ul className="qg-portal-links__list navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.qld.gov.au/services#/" data-analytics-link-group="qg-portal-links-link" target="_blank" rel="noopener noreferrer">Find services</a>
                  </li>
                  <li className="nav-item dropdown">
                    <button className="qg-portal-links__btn dropdown-toggle" type="button" id="qgPortalLinkBusiness" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Business and industry</button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="qgPortalLinkBusiness">
                      <div className="qg-dropdown-wrapper">
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/starting-business" data-analytics-link-group="qg-portal-links-link">Starting a business</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/running-business" data-analytics-link-group="qg-portal-links-link">Running a business</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/running-business/employing" data-analytics-link-group="qg-portal-links-link">Employing people</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/running-business/employing/payroll-tax" data-analytics-link-group="qg-portal-links-link">Payroll tax</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/industries" data-analytics-link-group="qg-portal-links-link">Industries</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/industries/invest" data-analytics-link-group="qg-portal-links-link">Investing in Queensland</a>
                        <a className="qg-dropdown-all dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.business.qld.gov.au/" data-analytics-link-group="qg-portal-links-all-category">All categories</a>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <button className="qg-portal-links__btn dropdown-toggle" type="button" id="qgPortalLinkGovernment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Government employees</button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="qgPortalLinkGovernment">
                      <div className="qg-dropdown-wrapper">
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/working-in-the-public-service" data-analytics-link-group="qg-portal-links-link">Working in the public service</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/human-resources" data-analytics-link-group="qg-portal-links-link">Human resources</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/finance-and-procurement" data-analytics-link-group="qg-portal-links-link">Finance and procurement</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/ict" data-analytics-link-group="qg-portal-links-link">Information and communication technology</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/service-delivery-and-community-support" data-analytics-link-group="qg-portal-links-link">Service delivery and community support</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/mail-facilities-vehicles" data-analytics-link-group="qg-portal-links-link">Mail, facilities and vehicles</a>
                        <a className="qg-dropdown-all dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.forgov.qld.gov.au/" data-analytics-link-group="qg-portal-links-all-category">All categories</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <div className="container-fluid qg-site-width qg-site-header-bottom">
          <div className="row align-items-center justify-content-between">
            <nav id="qg-site-nav" className="qg-navigation collapse" role="navigation" aria-label="main">
              <ul className="nav">
                <li className="nav-item hidden-lg hidden-md">
                  <a className="nav-link" href="/" data-analytics-link-group="qg-primary-nav-link">Home</a>
                </li>
                <li className="nav-item dropdown mega-dropdown">
                  <button className="nav-link dropdown-toggle" data-flip="false" data-toggle="dropdown" id="qgPrimaryNavForQueenslanders" aria-haspopup="true" aria-expanded="false">For Queenslanders</button>
                  <div className="dropdown-menu mega-menu" aria-labelledby="qgPrimaryNavForQueenslanders">
                    <ul>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/transport" data-analytics-link-group="qg-primary-nav-link">Transport and motoring</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/jobs" data-analytics-link-group="qg-primary-nav-link">Employment and jobs</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/education" data-analytics-link-group="qg-primary-nav-link">Education and training</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/about" data-analytics-link-group="qg-primary-nav-link">Queensland and its government</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/health" data-analytics-link-group="qg-primary-nav-link">Health and wellbeing</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/community" data-analytics-link-group="qg-primary-nav-link">Community support</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/emergency" data-analytics-link-group="qg-primary-nav-link">Emergency services and safety</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/law" data-analytics-link-group="qg-primary-nav-link">Your rights, crime and the law</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/environment" data-analytics-link-group="qg-primary-nav-link">Environment, land and water</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/housing" data-analytics-link-group="qg-primary-nav-link">Homes and housing</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/recreation" data-analytics-link-group="qg-primary-nav-link">Recreation, sport and arts</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/families" data-analytics-link-group="qg-primary-nav-link">Parents and families</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/disability" data-analytics-link-group="qg-primary-nav-link">People with disability</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/youth" data-analytics-link-group="qg-primary-nav-link">Youth</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/atsi" data-analytics-link-group="qg-primary-nav-link">Aboriginal and Torres Strait Islander peoples</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/seniors" data-analytics-link-group="qg-primary-nav-link">Seniors</a></li>
                    </ul>
                    <div className="d-flex flex-row-reverse">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/queenslanders" className="btn btn-global-primary" data-analytics-link-group="qg-primary-nav-browse-all">Browse all information and services</a>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/contact-us" data-analytics-link-group="qg-primary-nav-link">Contact us</a>
                </li>
              </ul>
            </nav>

            {/* <div class="header-location">
              <div class="header-location__title">
                <span class="header-location__label">Your location is</span>
                <button class="dropdown-toggle" type="button" data-toggle="collapse" data-target="#qg-location-dropdown" aria-expanded="false" aria-controls="qg-location-dropdown" aria-label="Your location is unknown">
                  <span class="location-name">unknown</span>
                </button>
                <span class="header-location__icon fa fa-map-marker"></span>
              </div>
              <div class="dropdown-menu qg-location-setter collapse" id="qg-location-dropdown">
                <div class="qg-location-default">
                  <p>Help us provide you with the most useful information by setting your location.</p>
                  <button class="btn btn-global-primary detect-location" type="button">Use device location</button>
                  <span class="qg-location-setter-divider">or</span>
                  <form class="qg-location-setter-form" action="#">
                    <label>Start typing your town or suburb</label>
                    <p class="qg-location-setter-error error hide">Please enter a location into the search box and try searching again.</p>
                    <input type="text" aria-label="Start typing your town or suburb, e.g. Cooladdi" data-choice="" placeholder="e.g. Cooladdi" />
                    <div class="qg-location-setter-autocomplete hide">
                      <ul>
                        <li><button><b>Coola</b>bine, Sunshine Coast Regional</button></li>
                        <li><button><b>Coola</b>bunia, South Burnett Regional</button></li>
                        <li><button><b>Coola</b>ddi, Murweh Shire</button></li>
                        <li><button><b>Coola</b>na, Somerset Regional</button></li>
                        <li><button><b>Coola</b>ngatta, Gold Coast City</button></li>
                      </ul>
                    </div>
                    <button class="btn btn-global-primary set-location" type="button">Set location</button>
                  </form>
                </div>
                <div class="qg-location-set hide">
                  <button class="btn btn-global-primary clear-location" type="button">Clear location</button>
                </div>
                <button class="qg-location-setter-close">Close</button>
              </div>
            </div> */}
            <div className="header-location__title">
              {Constants.DEBUG && user && (
                <>
                  <UncontrolledDropdown nav inNavbar style={{ position: 'relative', float: 'right' }}>
                    <DropdownToggle nav caret id="profileDropDown">
                      <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                      {user.email}
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={() => logoutWithRedirect()}
                      >
                        <FontAwesomeIcon icon={faSignOutAlt} /> Log
                        out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
            </div>


          </div>

        </div>

      </header>


      {/* <Breadcrumbs /> */}
    </>
  );
};

export default Header;
