import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants"
import CriticalAlert from '../components/swe/critical-alert';

export const TooManyResendAttempts = () => {

    const { logout } = useAuth0();
    const alertRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (Constants.DEBUG) {
                console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.');
            }
            logout({ localOnly: true });
            localStorage.clear();
            sessionStorage.clear();
        })();
    }, [])

    useEffect(() => {
        alertRef?.current?.focus();
    }, []);

    return (
        <>
            <div>
                <div>
                    <h1>Enter your email verification code</h1>
                    <CriticalAlert
                        heading="Too many resend attempts"
                        message={<>
                            <p>We were unable to successfully verify your email address.</p>
                            <p>Please <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                        </>}
                    />
                </div>
            </div>
        </>
    )
};

export default TooManyResendAttempts;