import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from "../constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { extractIpn, extractCl, updateSessionTokenIpn, isUserRequiredToAcceptTsCs } from "../utils/helpers";

import MFAHandler from "../utils/MFAHandler";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import Loading from "../components/Loading";
import { eligibilityCheck } from "../services/x-api";
import { hasUserReachedOrSurpassedRequestedACR } from "../utils/helpers";

import { v4 as uuidv4 } from 'uuid';

import { useSearchParams } from "react-router-dom";

// Redundant since we have no session token QSP from which this is derived
// after loginredirect
function parseJwt(token) {
    if (token) {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (error) {
            console.log("JWT token not found! %o", error);
            throw (error);
        }
    } else {
        return {};
    }
};

export const IpUplift = () => {
    if (Constants.DEBUG) { console.log('IpUplift ----------------------------------'); }

    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
    const [QDIStatus, setQDIStatus] = useState('');

    const sleep = m => new Promise(r => setTimeout(r, m));

    // Read QSPs passed on redirect from Auth0 from login/signup
    // For compatibility with iOS 16 we now use 'useSeachParams' instead of 'URLSearchParams'
    // See Bugfix IDP-6283
    //
    const [searchParams, setSearchParams] = useSearchParams();
    const state = searchParams.get('state');
    const session_token = searchParams.get('session_token');
    //////////////////////////////////////////////////////////////////

    if (Constants.DEBUG) {
        console.log("state value " + state);
        console.log("session_token value " + session_token);    
    }

    useEffect(() => {
        async function checkServiceStatusSession() {

            if (sessionStorage.getItem("QDI-Status") !== null) {
                while (sessionStorage.getItem("QDI-Status") !== "UP" && sessionStorage.getItem("QDI-Status") !== "DOWN") {
                    console.log('Waiting for QDI Service Status..');
                    await sleep(1000);
                }
                console.log('QDI Service Status recieved.');
                setQDIStatus(sessionStorage.getItem('QDI-Status'));
            }
        }

        checkServiceStatusSession();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {

            //  This code block is triggered when user starts a new session, ie, they did sign/login

            /*  Auth0 passes to us a state and session_token via QSP which we need to store. The session_token contains
                a ip_uplift_session_id hash. We use this with the state hash to call auth0 to trigger MFA enrollment
                at the end of uplift.
            */
            if (!state || !session_token) {
                // if no state OR session_token, redirect to unauth access
                if (Constants.DEBUG) console.log('Unauthenticated user access with no QSPs.');
                navigate("/unauthorised", { replace: true });
                return;
            }

            // Parse the recieved auth0 session token to extract required info to start uplift process
            let sessionToken = null;
            try {
                sessionToken = parseJwt(session_token);
            } catch (error) {
                if (Constants.DEBUG) console.log('Inavlid QSPs passed to QDI. Redirecting to unauthorised.');
                navigate("/unauthorised", { replace: true });
                return;
            }

            if (Constants.DEBUG) {
                console.log("User not authenticated. QSPs aquired and stored in local storage...");
                console.log("%cuser: %o\nsessionToken: %o\nipn: %o", 'color: #f96;', user, sessionToken, sessionToken.ipn);
            }

            // Preserve the session_token and state in localStorage since globalState is wiped after we do loginwithredirec
            localStorage.setItem('session_token', JSON.stringify(sessionToken));
            localStorage.setItem('state', JSON.stringify(state));

            //Save users requested ACR value and its derived IP:CL value
            saveGlobalState({ acr: sessionToken.ip_uplift_session.acr_values });
            window.sessionStorage.setItem("QDI-IP", extractIpn(sessionToken.ip_uplift_session.acr_values));

            /*  Log user in as ip1 to obtain an access token in order to call the mulesoft APIs. After this silent login
                Auth0 will redirect to /callback-ip1 which points to uplift. It will not pass the state and session_token
                in this instance but we now have those saved in localstorage
            */

            // IDP-4314 Retain original key/value prior to loginWithRedirect below
            // as it is null'd out afterwards and we will need the original when we come to MFA
            let clientId = process.env.react_app_auth0_clientId;
            const backupKey = `a0.spajs.txs.${clientId}`;
            const backupOfFirstAuth = window.sessionStorage.getItem(backupKey);
            window.sessionStorage.setItem(`${backupKey}.backup`, backupOfFirstAuth);

            // If from the DLA Eligibility Webapp, Auth0 will send an additional attributte in the session token.
            let isFromDLA = sessionToken.dl;

            if (isFromDLA) {
                const dlaUser = {
                    "secondDoc": "",
                    "result": "",
                };
                window.sessionStorage.setItem('dlaUser', JSON.stringify(dlaUser));
            }

            // TODO : whilst pending auth0 work to include a correlation-id in the
            // authentication session, generate one here to use in all requests
            window.sessionStorage.setItem('correlation-id', uuidv4());

            // set the value for cl (prompt for MFA, cl2) if user requested IPx:CL2,
            // their email is verified AND they have accepted latest Ts&Cs
            let isEmailVerified = sessionToken.email_verified;
            let requestsCL2 = globalState.acr.toUpperCase().includes('CL2');
            const isTsCsRequired = isUserRequiredToAcceptTsCs(sessionToken.tandc_accepted);
            let cl = (isEmailVerified && requestsCL2 && !isTsCsRequired) ? "cl2" : "cl1";

            if (Constants.DEBUG) {
                console.log('Terms and conditions last updated on the %s', Constants.TANDC_TIMESTAMP);
                console.log('User acceptence date %s', sessionToken.tandc_accepted);
                console.log('Is user required to accept TsCs? ', isTsCsRequired);
                console.log("requests cl2? : %o", requestsCL2);
                console.log('Users email verified,', isEmailVerified);
                console.log("User is requesting %o", sessionToken.ip_uplift_session.acr_values);
                console.log("User is currently %o", sessionToken.ipn);
                console.log("User from DLA, ", isFromDLA);
                console.log("Calling loginWithRedirect with IP1:%o to authenticate and get access token...", cl);
            }

            if (QDIStatus === 'UP') {
                // As the user is unauthenticated at this point with the QDI uplift portal, we auth
                // them at IP1 so we can get an access token to proceed with uplift

                loginWithRedirect({
                    acr_values: `urn:id.gov.au:tdif:acr:ip1:${cl}`,
                    redirectUri: `${window.location.origin}/callback-ip1`,
                });
            }
        }
    }, [QDIStatus]);

    useEffect(() => {
        if (isAuthenticated) {

            // Note that the user is authenticated but if they are uplifting they will only
            // be authenticated to IP1 at this stage in order to be able to get an access token

            // Note, if user only stepping up CL, for example CL1 to CL2, Auth0 does that, not QDI

            console.log('Authenticated user placed in uplift process...');
            let accessToken = null;

            (async () => {
                try {
                    accessToken = await getAccessTokenSilently({ ignoreCache: true });
                    if (Constants.DEBUG) { console.log('%cUser object%o', 'color: #c00;', user); }

                    // Due to the unreliability of this claim, set a globalState attrb
                    // Only set once claim is true
                    let mfaAvailable = user["http://oauth.tmr.qld.gov.au"].mfa_available;
                    if (typeof mfaAvailable !== 'undefined' && mfaAvailable)
                        saveGlobalState({ userCompletedMFA: true });
                }
                catch (error) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                }

                if (!globalState.sessionToken) {

                    let params = new URLSearchParams(window.location.search);
                    if (params.get("state") && params.get('session_token')) {

                        // User has logged in to re-commence uplift via SSO. We know this because we have been passed
                        // a state and session_token from auth0. Store these values in local storage and globalstate
                        if (Constants.DEBUG) { console.log('User logged in with SSO, read state and session_token from QSP and store in globalstate var...'); }
                        localStorage.setItem('state', JSON.stringify(params.get("state")));
                        let sessionToken = parseJwt(params.get('session_token'));
                        localStorage.setItem('session_token', JSON.stringify(sessionToken));
                        saveGlobalState({ sessionToken: sessionToken });
                        saveGlobalState({ idState: JSON.parse(localStorage.getItem('state')) });
                        saveGlobalState({ acr: sessionToken.ip_uplift_session.acr_values });

                    } else {

                        // user has an active session and going through uplift process but globalstate is empty

                        // Under certain circumstances, globalstate will be null such as when user has manually logged on
                        // and we call loginwithredirect. After authentication auth0 calls /callback-ip1 we land here and
                        // need to use the state and session_token we previously stored before the call to loginwithredirect
                        // as auth0 doesnt pass these in this instance.

                        // There are other times when this block will be called if for whatever reason globalstate has
                        // become null. This 'can' happen if user types in valid url in browser

                        if (Constants.DEBUG) { console.log('globalState.session is null, re-load from localStorage...'); }
                        let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token'));
                        if (!sessionTokenJSON) {
                            navigate("/session-timeout", { replace: true });
                            return;
                        }

                        // As we have loaded session_token from localstorage it may not reflect the users current ip level if
                        // they have since progressed further in the uplift process. We need to determine their correct ip level
                        // in order to call the correct page to contuinue uplift
                        if (sessionTokenJSON.ipn === "ip1p_nc" || sessionTokenJSON.ipn_status === "nc" || user["http://oauth.tmr.qld.gov.au"].ipn_status === "nc") {
                            sessionTokenJSON.ipn = "ip1p_nc";
                            sessionTokenJSON.ipn_status = "nc";
                        } else {
                            if (sessionTokenJSON.ipn !== "ip1p" && sessionTokenJSON.ipn !== "ip2") {
                                sessionTokenJSON.ipn = user["http://oauth.tmr.qld.gov.au"].ipn;
                                if (typeof sessionTokenJSON.ipn === 'undefined') {
                                    // if still no ipn value, set from users acr value
                                    sessionTokenJSON.ipn = extractIpn(user['http://oauth.tmr.qld.gov.au'].acr);
                                }
                            }
                        }

                        // Save these possible changes
                        localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
                        saveGlobalState({ sessionToken: sessionTokenJSON });
                        saveGlobalState({ idState: JSON.parse(localStorage.getItem('state')) });
                        saveGlobalState({ acr: sessionTokenJSON.ip_uplift_session.acr_values });

                        // Check if we have a dlaUser in our session, if so add to globalState
                        var _dlaUser = window.sessionStorage.getItem('dlaUser');
                        if (_dlaUser) {
                            saveGlobalState({ dlaUser: JSON.parse(_dlaUser) });
                            console.log('dla user ', globalState.dlaUser);

                        } else {
                            saveGlobalState({ dlaUser: null });
                            console.log('User not from DLA');
                        }
                    }

                }

                // We now get the users email and verified status from the claims object because
                // if the user calls support to change their email address, after logging in, if
                // they still have an active session they will be placed back in
                // the uplift process instead of OTP screen. This is because user object will not
                // have been updated with email verified false, same goes for session token. The
                // claims object will however.
                const claims = await getIdTokenClaims();
                saveGlobalState({ email: claims.email });
                saveGlobalState({ emailVerified: globalState.emailVerified === true ? globalState.emailVerified : claims.email_verified });

                const acrClaimIPN = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
                let ipn = globalState.sessionToken.ipn;
                if (ipn !== acrClaimIPN && ipn !== 'ip2' && ipn !== 'ip1p_nc') {
                    ipn = acrClaimIPN;
                    saveGlobalState({ sessionToken: updateSessionTokenIpn(ipn) });
                }

                const ipn_status = globalState.sessionToken.ipn_status;
                const acr = user["http://oauth.tmr.qld.gov.au"].acr;

                // Determine whether user needs to (re-)accept Terms and Conditions
                const tsAndCsRequired = isUserRequiredToAcceptTsCs(globalState.sessionToken.tandc_accepted);

                const dlaUser = globalState.dlaUser;

                if (Constants.DEBUG) {
                    console.log("globalState.dlaUser %o", dlaUser);
                    console.log('%cClaims%o', 'color: #c00;', claims);
                    console.log("Users globalState.sessionToken.ipn ->", ipn);
                    console.log("Users globalState.sessionToken.ipn_status ->", ipn_status);
                    console.log("Users current acr ->", acr);
                    console.log("TMR updated Tc&Cs on %o", Constants.TANDC_TIMESTAMP);
                    console.log("Users auth0 tandc_accepted attrib %o", globalState.sessionToken.tandc_accepted);
                    console.log("tsAndCsRerquired %o", tsAndCsRequired);
                    console.log("globalState.emailVerified %o", globalState.emailVerified);
                    console.log("ipn %o", ipn);
                    console.log("globalState.acr %o", globalState.acr);
                    console.log("globalState.userCompletedMFA %o", globalState.userCompletedMFA);
                    console.log('Has user met or exceeded requested ACR ? ',
                        hasUserReachedOrSurpassedRequestedACR(extractIpn(globalState.acr), ipn));
                }

                // User has now reached desired ACR or previously completed uplift and had to
                // re-accepted TsCs and/or re-verify email. We can now redirect to success page
                // for uplifted users or return to broker otherwise
                if (!tsAndCsRequired && user.email_verified
                    && hasUserReachedOrSurpassedRequestedACR(extractIpn(globalState.acr), ipn)) {

                    if (dlaUser && dlaUser.result != 'Eligible') { // DLA Eligibility Check

                        await eligibilityCheck(accessToken, claims.sub, dlaUser);
                        if (Constants.DEBUG) console.log('DL user after eligibility check %o', dlaUser);

                        if (dlaUser.result === 'Not eligible') {
                            if (Constants.DEBUG) console.log('Error, DL user not eligible!');
                            //TODO : Do what here, redirect to not eligible page?

                        } else if (dlaUser.result !== 'Eligible') {
                            if (Constants.DEBUG) console.log('DL user needs to provide further documentation...%s', ipn);
                            navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: ipn, dlaUser } });
                        } else {
                            if (Constants.DEBUG) console.log("User eligible for DL. Display success page.");
                            navigate("/ip-uplift/success", { replace: true });
                        }

                    } else {
                        if (Constants.DEBUG) console.log("Uplift complete. Display success page.");
                        navigate("/ip-uplift/success", { replace: true });
                    }

                }

                // User does not have tandc_accepted attrb or its out of date and needs to re-accept
                else if (tsAndCsRequired) {
                    if (Constants.DEBUG) { console.log("User needs to (re-)accept Ts&Cs"); }
                    navigate("/terms-and-conditions", { replace: true });
                }

                // Verify email and enrol in MFA
                else if (globalState.emailVerified === false) {
                    navigate("/ip-uplift/enter-otp", { replace: true });
                }

                else {

                    //
                    // User now needs to complete uplift if requested anything above IP1...
                    //

                    // First doc or second doc
                    if ((ipn === "ip1" || (ipn === "ip1p" && ipn_status !== "nc")) && globalState.emailVerified === true) {
                        navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: ipn, dlaUser } });
                    }

                    // name change (if required)
                    else if ((ipn === "ip1p_nc" || (ipn === "ip1p" && ipn_status === "nc")) && globalState.emailVerified === true) {
                        navigate("/ip-uplift/name-change-options", { replace: true });
                    }
                }

            })();
        }
    }, []);

    return <Loading />;

};

export default IpUplift;
